import React from "react"

import { Link } from "gatsby"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons
import ShoppingCart from "@material-ui/icons/ShoppingCart"

// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

// page styles
import introductionStyle from "assets/jss/material-kit-pro-react/views/registrationPageSections/introductionStyle.jsx"
const useStyles = makeStyles(introductionStyle)

export default function SectionForm() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={10} md={8}>
            <h2 className={classes.title}>Contest Info</h2>
            <p className={classes.closed}>
              *Please Note: On-line registration for Chilimania 2024 is now{" "}
              <b>CLOSED</b>. If you are a CASI cook, please contact{" "}
              <a href="mailto:cookoff@chilimania.com">cookoff@chilimania.com</a>
              .
            </p>
            <p className={classes.subtitle}>
              Read below to learn about the different contests Chilimania is
              offering this year. In order to reserve your booth for the
              following contests below, please be sure to register. Online
              registration is preferred and is available by clicking the
              "Register Now" button below. If you are unable to complete
              registration online, a PDF mail-in registration form is available
              for download below. You will need to pay online for your
              registration before competing. Additional documents on the contest
              rules and cook's letter are also available below if you did not
              recieve them after completing your registration.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
