import {
  container,
  dangerColor,
  description,
  section,
  textCenter,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"

const introductionStyle = {
  closed: {
    ...description,
    ...textCenter,
    color: dangerColor[0],
  },
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
    paddingTop: "0",
    justifyContent: "center",
    color: whiteColor,
  },
  formItem: {
    display: "flex",
    justifyContent: "center",
  },
  inputAdornment: {
    marginRight: "18px",
    position: "relative",
  },
  registerButton: {
    margin: "0 auto",
  },
  section: {
    ...section,
    padding: "70px 0px 0px",
  },
  subtitle: {
    ...description,
    ...textCenter,
    margin: "0 auto 30px auto",
  },
  title: {
    ...title,
    ...textCenter,
  },
}

export default introductionStyle
